/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPostCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/post-category', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPostCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/post-category/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPostCategory(ctx, object) {
      return new Promise((resolve, reject) => {
        axios
          .post('/post-category', object)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePostCategory(ctx, { id, object }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/post-category/${id}`, object)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePostCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/post-category/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
