/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admins', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admins/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admins/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, user) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admins', user)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    assignRole(ctx, { id, role }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admins/${id}/update-role`, role)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
