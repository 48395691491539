import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueLazyload from 'vue-lazyload'

import router from './router'
import store from './store'
import App from './App.vue'
import acl from './acl'
// Global Components
import './global-components'
import excel from 'vue-excel-export'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

Vue.use(VueLazyload)
Vue.use(acl)
Vue.use(excel)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
