// import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import * as config from '../../utils/config'
import route from '../../router'

function clearCookie(name, domain, path) {
  var domain = domain || document.domain
  var path = path || '/'
  document.cookie = `${name}=; expires=${+new Date()}; domain=${domain}; path=${path}`
}
export default {
  namespaced: true,
  state: {
    token: null,
    permissions: [],
    userLogin: {},
    regionsAcept: '',
    regions: '',
    hotelGeneral: [
      {
        label: 'Victoria Hotel',
        value: 1,
      },
      {
        label: 'Green lantern',
        value: 2,
      },
    ],
  },
  getters: {
    hotelGeneral(state) {
      return state.hotelGeneral
    },
    isAuthenticated(state) {
      return state.token != null
    },
    userLogin(state) {
      return state.userLogin
    },
    regions(state) {
      return state.regions
    },
    regionsAcept(state) {
      return state.regionsAcept
    },

  },
  mutations: {
    clearToken(state) {
      state.token = null
    },
    setRegionsAcept(state, regionsAcept) {
      state.regionsAcept = regionsAcept
    },
    setRegions(state, regions) {
      state.regions = regions
    },
    setUserLogin(state, userLogin) {
      state.userLogin = userLogin
    },
    setToken(state, token) {
      state.token = token
    },
    setPermissions(state, permissions) {
      state.permissions = permissions
    },
    clearPermissions(state) {
      state.permissions = []
    },
  },
  actions: {
    login(ctx, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/login', {
            email: credentials.email,
            password: credentials.password,
            hotelId: credentials.hotelId,
          })
          .then(result => {
            if (result.request.status === 200) {
              localStorage.setItem(config.TOKEN_KEY, result.body.token.accessToken)
              localStorage.setItem(config.TOKEN_EXPIRATION_KEY, new Date().getTime() + result.body.token.expiresIn * 1000)
              // // TODO:
              localStorage.setItem(config.USER, JSON.stringify(result.body.user))

              ctx.commit('setToken', result.body.token.accessToken)
              ctx.commit('setUserLogin', result.body.user)

              // ctx.dispatch('mePermissions')
              ctx.dispatch('setLogoutTimer', result.body.token.expiresIn * 1000)
            }
            resolve({
              success: true,
            })
          })
          .catch(error => {
            reject(error.message)
          })
      })
    },

    initAuth(ctx) {
      const token = localStorage.getItem(config.TOKEN_KEY)
      const tokenExpiration = localStorage.getItem(config.TOKEN_EXPIRATION_KEY)

      if (!token || !tokenExpiration) { return ctx.dispatch('logout') }

      if (new Date().getTime() > tokenExpiration || !token) { return ctx.dispatch('logout') }
      ctx.dispatch('getMeInfo')
      ctx.commit('setToken', token)
      ctx.dispatch('mePermissions')
      ctx.dispatch('setLogoutTimer', tokenExpiration - new Date().getTime())
      return true
    },
    setLogoutTimer(ctx, duration) {
      setTimeout(() => {
        ctx.commit('clearToken')
      }, duration)
    },
    getMeInfo(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/auth/me')
          .then(response => {
            ctx.commit('setUserLogin', response.body)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    mePermissions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admins/me/permission')
          .then(response => {
            if (response.body === '') { return ctx.dispatch('logout') }
            ctx.commit('setPermissions', response.body.permissions.map(element => element.name))
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    logout(ctx) {
      ctx.commit('clearToken')
      sessionStorage.clear()
      localStorage.removeItem(config.TOKEN_KEY)
      localStorage.removeItem(config.TOKEN_EXPIRATION_KEY)
      localStorage.removeItem(config.USER)
      localStorage.removeItem('hotelKey')
      localStorage.removeItem('hotelName')
      localStorage.removeItem('hotel')
    },
  },
}
