/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUserTracking(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/tracking', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUserRating(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/rating/customer-rating', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
