/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomerFlows(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/customer-flow', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    syncCustomers(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/customer-flow/sync')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomerRating(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/rating/${id}/customer-rating`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomerActivty(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/programs/customer/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomerFlow(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/customer-flow/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomerFlowQRLink(ctx, { id }) {
      console.log(id)
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/customer-flow/${id}/gen-url`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCustomerFlow(ctx, rescode) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/customer-flow/${rescode}/rms`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomerFlow(ctx, { id, object }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/admin/customer-flow/${id}`, object)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    genMultipleUrl(ctx, { object }) {
      console.log(object)
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/customer-flow/gen-multiple-url', object)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCustomerFlow(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/customer-flow/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelCustomerActivity(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/programs/customer/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
