import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import * as enpointConfig from '../utils/endpoints'

// axios
const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
})
axiosIns.interceptors.response.use(
  response =>
  // if (response.data.success) {
    Promise.resolve({
      body: response.data,
      request: response,
    }), // } else throw new APIError(response)

  error => {
    if (error.response && error.response.status && [401, 403].includes(error.response.status)) {
      // logout
      store.dispatch('authenticate/logout')
    }
    return Promise.reject(error)
  },
)
axiosIns.interceptors.request.use(config => {
  if (store.state.authenticate.token) {
    config.headers = {
      Authorization: `Bearer ${store.state.authenticate.token}`,
      'x-admin-hotel': localStorage.getItem('hotelId'),
      'x-hotel-key': localStorage.getItem('hotelKey'),
    }
  }
  return config
})
Vue.prototype.$http = axiosIns

export default axiosIns
