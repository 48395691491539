/* eslint-disable import/no-cycle */
import { createAcl, defineAclRules } from 'vue-simple-acl'
import store from '@/store/index'
import router from '../router'

const user = () => {
  const common = true
  const permissions = []
  if (store.state.authenticate.permissions.length > 0) {
    store.state.authenticate.permissions.forEach(element => {
      if (!permissions.includes(element)) {
        permissions.push(element)
      }
    })
  }
  return {
    common,
    permissions,
  }
}

const rules = () => defineAclRules(setRule => {
  setRule('common', user => user().common)
  // account
  setRule('account:list', user => user().permissions && user().permissions.includes('account:list'))
  setRule('account:create', user => user().permissions && user().permissions.includes('account:create'))
  setRule('account:update', user => user().permissions && user().permissions.includes('account:update'))
  setRule('account:read', user => user().permissions && user().permissions.includes('account:read'))
  setRule('account:delete', user => user().permissions && user().permissions.includes('account:delete'))
  // post
  setRule('post:list', user => user().permissions && user().permissions.includes('post:list'))
  setRule('post:create', user => user().permissions && user().permissions.includes('post:create'))
  setRule('post:update', user => user().permissions && user().permissions.includes('post:update'))
  setRule('post:read', user => user().permissions && user().permissions.includes('post:read'))
  setRule('post:delete', user => user().permissions && user().permissions.includes('post:delete'))
  // program
  setRule('program:list', user => user().permissions && user().permissions.includes('program:list'))
  setRule('program:create', user => user().permissions && user().permissions.includes('program:create'))
  setRule('program:update', user => user().permissions && user().permissions.includes('program:update'))
  setRule('program:read', user => user().permissions && user().permissions.includes('program:read'))
  setRule('program:delete', user => user().permissions && user().permissions.includes('program:delete'))
  // postCategory
  setRule('postCategory:list', user => user().permissions && user().permissions.includes('postCategory:list'))
  setRule('postCategory:create', user => user().permissions && user().permissions.includes('postCategory:create'))
  setRule('postCategory:update', user => user().permissions && user().permissions.includes('postCategory:update'))
  setRule('postCategory:read', user => user().permissions && user().permissions.includes('postCategory:read'))
  setRule('postCategory:delete', user => user().permissions && user().permissions.includes('postCategory:delete'))
  // programCategory
  setRule('programCategory:list', user => user().permissions && user().permissions.includes('postCategory:list'))
  setRule('programCategory:create', user => user().permissions && user().permissions.includes('postCategory:create'))
  setRule('programCategory:update', user => user().permissions && user().permissions.includes('postCategory:update'))
  setRule('programCategory:read', user => user().permissions && user().permissions.includes('postCategory:read'))
  setRule('programCategory:delete', user => user().permissions && user().permissions.includes('postCategory:delete'))
  // customerFlow
  setRule('customerFlow:list', user => user().permissions && user().permissions.includes('customerFlow:list'))
  setRule('customerFlow:create', user => user().permissions && user().permissions.includes('customerFlow:create'))
  setRule('customerFlow:update', user => user().permissions && user().permissions.includes('customerFlow:update'))
  setRule('customerFlow:read', user => user().permissions && user().permissions.includes('customerFlow:read'))
  setRule('customerFlow:delete', user => user().permissions && user().permissions.includes('customerFlow:delete'))
  // role
  setRule('role:list', user => user().permissions && user().permissions.includes('role:list'))
  setRule('role:create', user => user().permissions && user().permissions.includes('role:create'))
  setRule('role:update', user => user().permissions && user().permissions.includes('role:update'))
  setRule('role:read', user => user().permissions && user().permissions.includes('role:read'))
  setRule('role:delete', user => user().permissions && user().permissions.includes('role:delete'))
  // roomService
  setRule('roomService:list', user => user().permissions && user().permissions.includes('roomService:list'))
  setRule('roomService:create', user => user().permissions && user().permissions.includes('roomService:create'))
  setRule('roomService:update', user => user().permissions && user().permissions.includes('roomService:update'))
  setRule('roomService:read', user => user().permissions && user().permissions.includes('roomService:read'))
  setRule('roomService:delete', user => user().permissions && user().permissions.includes('roomService:delete'))
  // roomService
  setRule('rating:list', user => user().permissions && user().permissions.includes('rating:list'))
  setRule('rating:create', user => user().permissions && user().permissions.includes('rating:create'))
  setRule('rating:update', user => user().permissions && user().permissions.includes('rating:update'))
  setRule('rating:read', user => user().permissions && user().permissions.includes('rating:read'))
  setRule('rating:delete', user => user().permissions && user().permissions.includes('rating:delete'))
  // general
  setRule('general:list', user => user().permissions && user().permissions.includes('general:list'))
  setRule('general:create', user => user().permissions && user().permissions.includes('general:create'))
  setRule('general:update', user => user().permissions && user().permissions.includes('general:update'))
  setRule('general:read', user => user().permissions && user().permissions.includes('general:read'))
  setRule('general:delete', user => user().permissions && user().permissions.includes('general:delete'))
  // tracking
  setRule('tracking:list', user => user().permissions && user().permissions.includes('tracking:list'))
})
const simpleAcl = createAcl({
  user,
  rules,
  router,
})
export default simpleAcl
