/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProgramCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/programs-category', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProgramCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/programs-category/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProgramCategory(ctx, object) {
      return new Promise((resolve, reject) => {
        axios
          .post('/programs-category', object)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProgramCategory(ctx, { id, object }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/programs-category/${id}`, object)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProgramCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/programs-category/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
