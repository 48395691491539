/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOrders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/order', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrder(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/order/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCustomerFlow(ctx, object) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/customer-flow', object)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomerFlow(ctx, { id, object }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/admin/customer-flow/${id}`, object)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCustomerFlow(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/customer-flow/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
