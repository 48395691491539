/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    optionsActive: [
      {
        label: 'Active',
        value: true,
      },
      {
        label: 'Inactive',
        value: false,
      },
    ],
  },
  getters: {
    optionsActive: state => state.optionsActive
  },
  mutations: {},
  actions: {
    fetchRatings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/rating', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRating(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/rating/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRating(ctx, rating) {
      return new Promise((resolve, reject) => {
        axios
          .post('/rating', rating)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRating(ctx, { id, rate }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/rating/${id}`, rate)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRating(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/rating/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
