/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    listRolesOptions: [
      // admin user
      {
        label: 'List admin user',
        value: 'account:list',
      },
      {
        label: 'Create admin user',
        value: 'account:create',
      },
      {
        label: 'Update admin user',
        value: 'account:update',
      },
      {
        label: 'Read admin user',
        value: 'account:read',
      },
      {
        label: 'Delete admin user',
        value: 'account:delete',
      },
      // post
      {
        label: 'List post',
        value: 'post:list',
      },
      {
        label: 'Create post',
        value: 'post:create',
      },
      {
        label: 'Update Local Tips',
        value: 'post:update',
      },
      {
        label: 'Read post',
        value: 'post:read',
      },
      {
        label: 'Delete post',
        value: 'post:delete',
      },
      // program
      {
        label: 'List program',
        value: 'program:list',
      },
      {
        label: 'Create program',
        value: 'program:create',
      },
      {
        label: 'Update program',
        value: 'program:update',
      },
      {
        label: 'Read program',
        value: 'program:read',
      },
      {
        label: 'Delete program',
        value: 'program:delete',
      },
    ],
  },
  getters: {
    listRolesOptions(state) {
      return state.listRolesOptions
    },
  },
  mutations: {},
  actions: {
    fetchPermission(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/permissions')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoleManager(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/role', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/role/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRole(ctx, object) {
      return new Promise((resolve, reject) => {
        axios
          .post('/role', object)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRole(ctx, { id, object }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/role/${id}`, object)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/role/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
