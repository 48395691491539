import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    lanCodeOptions: [
      {
        label: 'Tiếng việt',
        value: 'vi_VN',
        disable: false,
      },
      {
        label: 'English',
        value: 'en_US',
        disable: true,
      },
    ],
    statusOptions: [
      {
        label: 'DRAFT',
        value: 'draft',
      },
      {
        label: 'PENDING',
        value: 'pending',
      },
      {
        label: 'APPROVED ',
        value: 'approved',
      },
      {
        label: 'CANCEL  ',
        value: 'cancel',
      },
    ],
    weekDayAvailableOptions: [
      {
        label: 'Monday',
        value: '1',
      },
      {
        label: 'Tuesday',
        value: '2',
      },
      {
        label: 'Wednesday',
        value: '3',
      },
      {
        label: 'Thursday',
        value: '4',
      },
      {
        label: 'Friday',
        value: '5',
      },
      {
        label: 'Saturday',
        value: '6',
      },
      {
        label: 'Sunday',
        value: '0',
      },
    ],
  },
  getters: {
    weekDayAvailableOptions: state => state.weekDayAvailableOptions,
    statusOptions: state => state.statusOptions,
    lanCodeOptions: state => state.lanCodeOptions,
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {},
}
