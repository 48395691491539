/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    optionsActive: [
      {
        label: 'Active',
        value: true,
      },
      {
        label: 'Inactive',
        value: false,
      },
    ],
    genderOptions: [
      {
        label: 'Male',
        value: 1,
      },
      {
        label: 'Female',
        value: 2,
      },
      {
        label: 'All',
        value: 3,
      },
    ],
    countries: []
  },
  getters: {
    optionsActive: state => state.optionsActive,
    countries: state => state.countries,
    genderOptions: state => state.genderOptions
  },
  mutations: {
    setCountries(state, countries) {
      state.countries = countries
    },
  },
  actions: {
    fetchCountries(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/pe-rule/countries')
          .then(response => {
            const tranferData = response.body.map(x => ({
              label: x.name,
              value: x.country_code
            }))
            ctx.commit('setCountries', tranferData)
            // resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchPeRules(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/pe-rule', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPeRule(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/pe-rule/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPeRule(ctx, rating) {
      return new Promise((resolve, reject) => {
        axios
          .post('/pe-rule', rating)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePeRule(ctx, { id, rate }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/pe-rule/${id}`, rate)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePeRule(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/pe-rule/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
