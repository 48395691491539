/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    cukcukBranch: [],
    paymentStatus: [
      {
        label: 'Unpaid',
        value: 0,
      },
      {
        label: 'Not yet collected',
        value: 1,
      },
      {
        label: 'Debit',
        value: 2,
      },
      {
        label: 'Collected',
        value: 3,
      },
      {
        label: 'Cancelled',
        value: 4,
      },
      {
        label: 'Temporarily cancel',
        value: 5,
      },

    ],
  },
  getters: {
    cukcukBranch(state) {
      return state.cukcukBranch
    },
    paymentStatus(state) {
      return state.paymentStatus
    },
  },
  mutations: {
    setCukCukBranch(state, cukcukBranch) {
      state.cukcukBranch = cukcukBranch.map(x => ({
        label: x.name,
        value: x.id,
      }))
    },
  },
  actions: {
    fetchCukCukBranch(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/cukcuk/branch')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchCukCukCategory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/cukcuk/category', {
            params: queryParams,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    cancelOrder(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/order/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
