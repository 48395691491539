/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    postCategories: [],
    isActiveOptions: [
      {
        label: 'Active',
        value: true,
      },
      {
        label: 'Inactive',
        value: false,
      },
    ],
  },
  getters: {
    postCategories(state) {
      return state.postCategories
    },
    isActiveOptions(state) {
      return state.isActiveOptions
    },
  },
  mutations: {
    setPostCategory(state, postCategories) {
      state.postCategories = postCategories.map(x => ({
        label: x.name,
        value: x.id,
      }))
    },
  },
  actions: {
    fetchCategories(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/post-category', {
            params: {
              page: 1,
              take: 50,
            },
          })
          .then(response => {
            // console.log(response)
            ctx.commit('setPostCategory', response.body.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchPosts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/posts', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPost(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/posts/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPost(ctx, post) {
      return new Promise((resolve, reject) => {
        axios
          .post('/posts', post)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePost(ctx, { id, post }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/posts/${id}`, post)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePost(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/posts/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
