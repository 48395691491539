import store from '@/store/index'

export default function ({ to, from, next }) {
  if (!store.state.authenticate.token ) {
    store.dispatch('authenticate/initAuth')
    store.dispatch('peRule/fetchCountries')

    if (!store.state.authenticate.token) {
      return next({ name: 'login' })
    }
  }
  return next()
}
