// export const BASE_URL = 'https://backend.marvyk.com'
export const BASE_URL = 'http://localhost:3000'
// export const BASE_URL = 'http://45.32.117.198:3000/'

export const USER = {
  // Authen
  AUTH_LOGIN: `${BASE_URL}/auth/login`,
  REGISTER_USER: '/v1/accounts:signUp',
  AUTH_SSO_LOGIN: `${BASE_URL}/auth/login/sso`,
  AUTH_ME: `${BASE_URL}/auth/me`,
}
export const PRODUCT = {
  GET_LIST_PRODUCTS: `${BASE_URL}/product`,
  GET_DETAIL_PRODUCT: `${BASE_URL}/product/`,
  ADD_PRODUCT: `${BASE_URL}/product`,
  UPDATE_PRODUCT: `${BASE_URL}/product/`,
  DELETE_PRODUCT: `${BASE_URL}/product/`,
}
export const CATEGORIES = {
  GET_LIST_CATEGORIES: `${BASE_URL}/category`,
}
export const VENDOR = {
  GET_LIST_VENDORS: `${BASE_URL}/vendor`,
}
